import { render, staticRenderFns } from "./SelectList.vue?vue&type=template&id=bba668be&scoped=true"
import script from "./SelectList.vue?vue&type=script&lang=js"
export * from "./SelectList.vue?vue&type=script&lang=js"
import style0 from "./SelectList.vue?vue&type=style&index=0&id=bba668be&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bba668be",
  null
  
)

export default component.exports