<template>
	<div id="gradelist-wrapper">
		<p>清华大学马约翰杯{{ this.currentyear }}-{{ this.currentyear + 1 }}赛季</p>
		<div v-if="ableToDownload">
			<el-date-picker
				v-model="tableYear"
				clearable
				value-format="yyyy"
				type="year"
				@change="changeYear()"
				editable
				placeholder="选择年份，默认当前赛季"
				:default-value="this.currentdate"
			>
			</el-date-picker>
			<el-link
				target="tempIframe"
				:href="downloadUrl"
				:underline="false"
				style="margin: 15px"
			>
				<el-button type="primary"
					><i class="el-icon-download"></i> 导出此赛季选项表(Excel)</el-button
				>
			</el-link>
		</div>
		<div id="roll-notice">
			<span>若表格太宽而无法完全显示，请左右滑动查看</span>
		</div>
		<el-table
			id="selectlist-table"
			:data="tableData"
			border
			style="width: 100%; margin-top: 20px"
			max-height="600"
		>
			<el-table-column fixed prop="dept" label="院系" width="120" />
			<el-table-column
				fixed
				prop="total"
				label="任选数量"
				width="80"
			/>

			<el-table-column
				v-for="(item, index) in this.games"
				:key="index"
				:label="item.name"
			>
				<!-- 数据的遍历  scope.row就代表数据的每一个对象-->
				<template slot-scope="scope">
					<span>{{ getSelect(scope.row, item) }}</span>
				</template>
			</el-table-column>
		</el-table>
		<iframe name="tempIframe" style="display: none"></iframe>
		<el-backtop>
			<i class="el-icon-caret-top"></i>
		</el-backtop>
	</div>
</template>
<style scoped>
#gradelist-wrapper i {
	font-size: 16px;
}
.el-radio {
	margin: 0 20px 0 20px;
}
.el-date-editor {
	margin: 0 20px 0 0;
}
#roll-notice {
	margin-top: 15px;
	color: grey;
	font-size: small;
}
#gradelist-wrapper {
	height: 100%;
	width: 100%;
}
#gradelist-table {
	width: 90%;
	margin: 0 auto;
}
</style>

<script>
import axios from "axios";
export default {
	name: "SelectList",
	data() {
		return {
			games: [],
			opt_games: [],
			currentyear: 0,
			currentdate: null,
			tableYear: null,
			tableData: [],
			tableData1: [],
			tableData2: [],
			ableToDownload: 0,
			downloadUrl: "/api/select/download",
		};
	},
	methods: {
		messageBox(msg, type) {
			this.$message[type](msg);
		},
		getStatOf() {
			axios({
				url: "/api/dept/select/get-all",
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("token"),
				},
			})
				.then((res) => {
					this.tableData1 = res.data.select1;
					this.tableData2 = res.data.select2;
					let games_lists = res.data.game;
					this.games = []
					this.games.push(...games_lists.must, ...games_lists.res, ...games_lists.opt);
					for (let game of games_lists.opt) {
						this.opt_games.push(game.id);
					}
					this.getTabledata();
				})
				.catch((err) => {
					this.messageBox(
						"获取赛季成绩失败， " + err?.response?.data?.Message ??
							"网络断开或神秘错误",
						"error"
					);
					console.log("Get select statistics Failed");
					console.log(err.response);
					return -1;
				});
		},
		getTabledata() {
			this.tableData = [];
			let game_count = {'dept': '', 'total': '', cnt: {}};
			for (let game of this.games) {
				let count = 0;
				for (let dept of this.tableData1) {
					if (dept.sel.indexOf(game.id) !== -1) count++;
				}
				for (let dept of this.tableData2) {
					if (dept.sel.indexOf(game.id) !== -1) count++;
				}
				game_count.cnt[game.id] = count;
			}
			this.tableData.push(game_count);
			let jia_title = {'dept': '甲组', 'total': ''};
			this.tableData.push(jia_title);
			for (let dept_data of this.tableData1) {
				dept_data.total = 0;
				for (let id of dept_data.sel) {
					if (this.opt_games.indexOf(id) !== -1) dept_data.total ++;
				}
				this.tableData.push(dept_data);
			}
			let yi_title = {'dept': '乙组', 'total': ''};
			this.tableData.push(yi_title);
			for (let dept_data of this.tableData2) {
				dept_data.total = 0;
				for (let id of dept_data.sel) {
					if (this.opt_games.indexOf(id) !== -1) dept_data.total ++;
				}
				this.tableData.push(dept_data);
			}
		},
		getSelect(deptitem, gameitem) {
			if (deptitem.dept === '') return deptitem.cnt[gameitem.id];
			if (deptitem.total === '') return '';
			let itemString = '';
			if (deptitem.sel.indexOf(gameitem.id) !== -1) itemString += "--选项--";
			if (deptitem.part.indexOf(gameitem.id) !== -1) itemString += "非全日制";
			return (itemString === '') ? "-----" : itemString;
		},
		changeYear() {
			if (this.tableYear === null) return;
			this.currentyear = Number(this.tableYear);
			axios({
				url: "/api/dept/select/get-all?year=" + String(this.tableYear),
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("token"),
				},
			})
				.then((res) => {
					this.tableData1 = res.data.select1;
					this.tableData2 = res.data.select2;
					let games_lists = res.data.game;
					this.games = []
					this.games.push(...games_lists.must, ...games_lists.res, ...games_lists.opt);
					for (let game of games_lists.opt) {
						this.opt_games.push(game.id);
					}
					this.getTabledata();
				})
				.catch((err) => {
					this.messageBox(
						"获取赛季成绩失败， " + err?.response?.data?.Message ??
							"网络断开或神秘错误",
						"error"
					);
					console.log("Get select statistics Failed");
					console.log(err.response);
					return -1;
				});
			this.downloadUrl = "/api/select/download?year=" + String(this.tableYear);
		},
	},
	mounted() {
		this.getStatOf();
		if (localStorage.getItem("token")) {
			axios.defaults.headers.common["Authorization"] = localStorage.getItem(
				"token"
			);
			axios({
				url: "/api/game/getyear",
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("token"),
				},
			})
				.then((res) => {
					this.currentyear = res.data.year;
					this.currentdate = new Date().setFullYear(this.currentyear);
				})
				.catch((err) => {
					this.messageBox(
						"获取当前赛季失败， " + err?.response?.data?.Message ??
							"网络断开或神秘错误",
						"error"
					);
					console.log("Get current year Failed");
					console.log(err.response);
				});
			axios({
				url: "/api/hello-user",
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("token"),
				},
			})
			.then((res) => {
				if (res.data.usertype === 'admin') {
					this.ableToDownload = 1;
				}
			})
			.catch((err) => {
				this.messageBox(
					"获取身份信息失败，" + err?.response?.data?.Message ?? "网络断开或神秘错误",
					"error"
				);
				console.log(err.response);
			});
		}
	},
};
</script>
